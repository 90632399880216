/* ------------------  angle section css---------------------------- */

.devilflip-angle-pumps {
    width: 84%;
    margin-left: auto;
}

@media (max-width:1199px) {
    .devilflip-angle-pumps {
        width: 100%;
    }
}

