.mobile-friendly-gradient-background {
    background-image: -webkit-linear-gradient( 209deg, #ffffff 0%, #ff0b0b 0%, #960000 25%, #003596 75%, #02c9ff 100%);
    .mobile-friendly-section {
            padding-top: 100px;
            padding-bottom: 100px;
            overflow: hidden;
        .mobile-friendly-container {
            .mobile-friendly-phone-img {
                width: 850px;
            }
            .mobile-friendly-text-part {
                color: #fff;
                max-width:600px;
                margin:0 auto;
                .mobile-friendly-icons img {
                    width: auto;
                    height: auto;
                    max-width: 32px;
                }
                .mobile-friendly-icons img + img {
                    margin-left:22px;
                }
            }
            .mobile-friendly-devilflip-paragraph {
                font-weight: 400;
                font-size: 17px;
                max-width: 430px;
                line-height: 140%;
            }
        }
    }
    .devilflip-h3-heading {
        font-size: 32px;
    }


}

@media (min-width:1301px) and (max-width:1450px){
    .mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-phone-img {
        width: 660px;
    }
    .mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-text-part {
        max-width: 540px;
        margin: 0 auto 0 0;
    }
    .mobile-friendly-gradient-background .devilflip-h3-heading {
        font-size: 28px;
    }

}

@media (min-width:1200px) and (max-width:1300px){
.mobile-friendly-gradient-background .devilflip-h3-heading {
    font-size: 28px;
}
}

@media (min-width:992px) and (max-width:1199px) {
    .mobile-friendly-gradient-background .devilflip-h3-heading {
        font-size: 24px;
    }
}

@media (min-width:768px) and (max-width:991px) {
.mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-text-part {
    max-width: 700px;
}
.mobile-friendly-gradient-background .devilflip-h3-heading {
    font-size: 36px;
}
.mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-devilflip-paragraph {
    font-size: 24px;
    max-width: 100%;
    line-height: 160%;
}
.mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-text-part .mobile-friendly-icons img {
    max-width: 42px;
}
.mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-text-part .mobile-friendly-icons img + img {
    margin-left: 32px;
}
.mobile-friendly-gradient-background .mobile-friendly-section {
    padding-bottom: 50px;
}


}

@media (max-width:767px){
    .mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-text-part {
        padding: 0 15px;
    }
    .mobile-friendly-gradient-background .mobile-friendly-section {
        padding-top: 40px;
        padding-bottom: 30px;
    }
    .mobile-friendly-gradient-background .devilflip-h3-heading {
        font-size: 15px;
    }
    .mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-devilflip-paragraph {
        font-size: 11px;
        max-width: 100%;
        line-height: 150%;
    }
    .mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-text-part .mobile-friendly-icons img {
        max-width: 18px;
    }
    .mobile-friendly-gradient-background .mobile-friendly-section .mobile-friendly-container .mobile-friendly-text-part .mobile-friendly-icons img + img {
        margin-left: 14px;
    }

}

