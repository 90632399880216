.oneside-chosen-section {
    .devilflip-heading-secondry-color {
        text-shadow: 9px 0px 0px #d5504c, 0px 0 0 #d5504c, 0px 12px 3px #fff;
    }
}

.onside-video {
    background: rgb(32, 34, 37);
    padding-top: 32px;
    border-radius: 13px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
}
.onside-video video {
    display: block;
}
.frame-dots {
    position: absolute;
    left: 5px;
    top: 0;
    padding: 11px;
    display: flex;
    align-content: center;
}
.frame-dots div {
    width: 11px;
    height: 11px;
    background: #ea4848;
    border-radius: 50%;
    margin-right: 7px;
}
.frame-dots div:nth-child(2) {
    background:#eda830;
}
.frame-dots div:nth-child(3) {
    background:#48c936;
}
@media (min-width:992px) and (max-width:1199px) {
    .home-background-image {
        .background-container {
            .devilFlip-background-paragraph {
                width: 100%;
            }
        }
    }
    .onside-video{
        margin-left:0;
    }
}

@media  (max-width:1199px) {
    .onside-video{
        margin-left:0;
    }
}
@media  (max-width:767px) {
    .oneside-chosen-section .devilflip-heading-secondry-color {
        text-shadow: 5px 1px 0px #d5504c, 0px 0 0 #d5504c, 0px 9px 3px #fff;
    }
    
    .onside-video {
        padding-top: 14px;
        border-radius: 9px;
    }
    .frame-dots {
        left: 3px;
        padding: 5px;
    }
    .frame-dots div {
        width: 5px;
        height: 5px;
        margin-right: 3px;
    }
}